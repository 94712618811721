import * as React from "react"
import * as ReactDOM from "react-dom/client"

import "./assets/scss/style.scss"
import App from "./App"

import reportWebVitals from "./reportWebVitals"
import Loader from "./components/loader/Loader"
import { TourProvider } from '@reactour/tour'
import CookieManagement from "./components/cookie-management/CookieManagement"

// Google Analytics 
import ReactGA from 'react-ga4'

//https://docs.react.tours/quickstart
//tour styles
const styles = {
  popover: (base) => ({
    ...base,
    backgroundColor: '#1b272d',
    color: '#fff',
    '--reactour-accent': '#B2D953',
  }),
  close: (base) => ({ ...base, color: '#fff', left: 'auto', right: 8, top: 8 }),
  badge: (base) => ({ ...base, backgroundColor: '#B2D953' }),
}
//tour steps
const steps = [
  {
    selector: '.first-step',
    content: 'This is my first Step',
  },
  // ...
]

// Add the event listeners for handling chunk loading errors
window.addEventListener('error', event => {
  if (event.message && event.message.includes('Loading chunk')) {
    console.log('Chunk loading error detected. Reloading the page.');
    window.location.reload();
  }
});

window.addEventListener('unhandledrejection', event => {
  if (event.reason && event.reason.message && event.reason.message.includes('Loading chunk')) {
    console.log('Chunk loading error detected in unhandled promise rejection. Reloading the page.');
    window.location.reload();
  }
});

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID)

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  
      <React.Suspense fallback={<Loader />}>
        <TourProvider steps={steps} styles={styles}>
          <CookieManagement>
            <App />
          </CookieManagement>
        </TourProvider>
      </React.Suspense>
      
  // </React.StrictMode>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
