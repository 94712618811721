
import { CookieManager } from "react-cookie-manager";
import "react-cookie-manager/style.css";
const CookieManagement = (props) => {
    const CookieCategories = {
        Analytics: true,
        Social: false,
        Advertising: false,
      }
    const legal_url = process.env.REACT_APP_LEGAL_URL
    return (
        <CookieManager
            translations={{
                title: "Maintel Cookie Preferences",
                message: "We use cookies to improve your experience and our understanding. Essential cookies are always enabled as they are necessary for the website to function properly.",
                buttonText: "Accept All",
                declineButtonText: "Accept Essentials",
                manageButtonText: "Manage Cookies",
                privacyPolicyText: "Privacy Policy",
                                
                // Manage consent modal
                manageTitle: "Maintel Cookie Preferences",
                manageMessage: "Manage your cookie preferences below. Essential cookies are always enabled as they are necessary for the website to function properly.",

                // Essential cookies section
                manageEssentialTitle: "Essential",
                manageEssentialSubtitle: "Required for the Maintel Portal to function properly",
                manageEssentialStatus: "Status: Always enabled",
                manageEssentialStatusButtonText: "Always On",

                // Analytics cookies section
                manageAnalyticsTitle: "Analytics",
                manageAnalyticsSubtitle: "Help us understand how visitors interact with our website",

                // Status messages
                manageCookiesStatus: "Status: {{status}} on {{date}}", // Supports variables
                manageCookiesStatusConsented: "Consented",
                manageCookiesStatusDeclined: "Declined",

                // Buttons in manage modal
                manageCancelButtonText: "Cancel",
                manageSaveButtonText: "Save Preferences"
            }}
            showManageButton={true}
            cookieKey="maintel-portal-cookie"
            privacyPolicyUrl={legal_url}
            displayType="modal"
            theme="light"
            onManage={(preferences) => {
                if (preferences) {
                  console.log("Cookie preferences updated:", preferences);
                }
              }}
        >
            {props.children}
        </CookieManager>
    )
};
export default CookieManagement;