import { client } from "../../../api/client"
export const writeAudit = (auditJson) => {
    if (process.env.REACT_APP_USELOCALDATA === "true") {
        return 200
    } else {
        try {
            client.post("audit", JSON.stringify(auditJson))
        }
        catch (e) {
            console.log('Failed to audit with payload', auditJson)
        }
    }
}